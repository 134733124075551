/***** BOF PWA *****/

#installpwaBox
{
min-height:100%;
}

#installPWA h1 > img
{
width:128px;
height:128px;
}

#addToHomeScreenIcon
{
font-size:1.5em;
line-height:1em;
}

#pwaUpdate
{
display:none;
position:fixed;
top:0;
right:0;
bottom:0;
left:0;
width:100vw;
height:100vh;
overflow:hidden;
z-index: 999999999;
background:#000000;
color:#ffffff;
}

#pwaMessage
{
display:flex;
width:100%;
height:100%;
align-items:center;
justify-content:center;
}

/***** EOF PWA *****/
