/***** BOF AUTH *****/

#signInFormBox
{
display:flex;
min-width:100vw;
min-height:100vh;
align-items:center;
justify-content:center;
}

#tokenForm
{
display: none;
z-index: 9999999999;
background: #eeeeee;
width: 100vw;
height: 100vh;
overflow-x:hidden;
overflow-y:auto;
}

#tokenForm form
{
margin: 30px auto;
width: 320px;
max-width: 90%;
}

#signInForm .form-group input
{
max-width:320px;
margin:0 auto;
}

/***** EOF AUTH *****/
