/*** BOF QUIRKS ***/
/** no 300 ms delay for click in touch devices */
html {
  touch-action: manipulation;
  -webkit-touch-action: manipulation;
  -webkit-overflow-scrolling: none;
}

/* DISABLE iOS Safari bouncing */
/*
html,body
{
position:fixed;
}
*/

*:not(input, textarea) {
  -webkit-touch-callout: none;
  /* Disable touch hold callout */
  -webkit-user-select: none;
  /* Disable selection/Copy */
}

img {
  user-drag: none;
  -webkit-user-drag: none;
}

/*** EOF QUIRKS ***/
