/***** BOF RESET BROWSER DEFAULTS *****/

html {
	width: 100vw;
	height: 100vh;
	overflow: hidden;
}

body {
	border: 0;
	margin: 0;
	padding: 0;
	color: #666666;
	font-family: sans-serif;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
}

a, a:visited {
	text-decoration: none;
	outline: none;
	color: #000000;
}

a.whiteLink, a.whiteLink:visited {
	text-decoration: none;
	outline: none;
	color: #ffffff;
}

a:hover {
	text-decoration: underline;
}

a img {
	border: 0;
}

p {
	line-height: 125%;
}

label {
	margin: 0;
}

/***** EOF RESET BROWSER DEFAULTS *****/

/***** BOF SCROLLING MODULES *****/

.scroller {
	width: 100%;
	position: relative;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
}

.scrolled {
	position: relative;
	height: auto;
}

::-webkit-scrollbar {
	width: 4px;
}

::-webkit-scrollbar-track {
	background: transparent;
}

::-webkit-scrollbar-thumb {
	background: #dddddd;
}

/***** EOF SCROLLING MODULES *****/

/***** BOF GENERAL *****/

@font-face {
	font-family: 'Gotham-Medium';
	src: url('../fonts/Gotham-Medium.otf') format('opentype')
}

@font-face {
	font-family: 'Gotham-Bold';
	src: url('../fonts/Gotham-Bold.otf') format('opentype')
}

body {
	font-family: 'Gotham-Bold';
	overflow-y: scroll;
}

.centerL {
	display: flex;
	align-items: center;
}

.centerC {
	display: flex;
	align-items: center;
	justify-content: center;
}

.centerR {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

/***** EOF GENERAL *****/

/***** BOF LOGIN CARD *****/

#signInForm {
	display: none;
}

#signInFormBox {
	color: #000000;
	align-items: unset;
	top: 20vh;
	position: absolute;
	min-height: unset;
}

#signInFormBox.loginBrowser {
	align-items: center;
	top: unset;
	position: unset;
	min-height: 100vh;
}

#signInFormBox #logoHeader {
	max-width: 300px;
}

#signInFormBox #logoFooter {
	max-width: 150px;
}

#signInForm {
	margin: 50px auto;
}

#signInForm .form-group {
	margin: 30px auto;
}

#signInForm .form-group:first-child, #signInForm .form-group:last-child {
	margin: 0 auto;
}

#signInForm .form-group input, #signInForm .form-group select {
	text-align: center;
	text-align-last: center;
	background-color: #F2F2F2;
	color: #606060;
}

#checkLogin {
	background: #E63329;
	color: #FFFFFF;
}

::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #606060;
	opacity: 1;
	/* Firefox */
}

:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #606060;
}

::-ms-input-placeholder {
	/* Microsoft Edge */
	color: #606060;
}

.loading {
	background-image: url(../../media/img/loading.gif);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: 32px 32px;
	min-width: 32px;
	min-height: 32px;
}

.loading>* {
	visibility: hidden;
}

#boxPWA {
	max-width: 300px;
	margin: 30px auto;
}

.continueBrowser {
	margin-top: 30px;
	text-decoration: underline;
	color: #E63329;
}

#downloadPWAOtheriOS, #copyLink {
	background: #E63329;
	color: #FFFFFF;
	border-color: transparent;
}

#downloadPWAOtheriOS {
	display: none;
}

#copyLink:focus {
	box-shadow: unset;
}

#infoOldDeviceIOS{
	display: none;
}

/***** EOF LOGIN CARD *****/

/***** BOF CARD *****/

#boxCard {
	background-image: url(../../media/img/background.png);
	background-repeat: no-repeat;
	background-size: 100%;
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	font-family: 'Gotham-Medium';
}

#headerCard {
	background-color: #EBF2F7;
	width: 100%;
	padding: 7px 20px;
	margin: 50px auto;
	color: #000000;
}

#headerCard img {
	width: auto;
	height: 40px;
}

#logo-cral-menarini {
	max-width: 250px;
	padding: 10px;
	background: rgba(255, 255, 255, 0.6);
	border-radius: 15px;
	margin: 10px 20px;
}

#logo-cral-menarini img {
	width: 100%;
}

#numberMatricola {
	background: #e63329;
	color: #fff;
	padding: 10px 40px;
	border-radius: 15px;
	margin: 20px;
	text-align: center;
}

#infoUser {
	margin: 0 auto;
	font-family: Gotham-Bold;
	position: fixed;
	width: 100%;
	padding: 0 20%;
	bottom: 120px;
}

#infoUser .labelIU {
	text-align: center;
	color: #000000;
}

#infoUser .boxIU {
	margin: 0px 0px 35px;
	padding: 5px;
	background: #F7F7F7;
	border: 1px solid #606060;
	text-align: center;
	border-radius: 10px;
	color: #000000;
}

#infoUser .boxIU:last-child {
	color: #E63329;
	margin-bottom: 0;
}

#logo-noe {
	position: fixed;
	bottom: 30px;
	width: 100%;
	text-align: center;
}

#logout {
	position: fixed;
	bottom: 20px;
	left: 20px;
	width: 30px;
	height: 30px;
	border-radius: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(0, 0, 0, 0.2);
	color: #000000;
	font-size: 25px;
}

#logo-noe img {
	height: 50px;
}

@media screen and (max-height: 670px) {
	#headerCard {
		margin: 25px auto;
	}
	#logo-cral-menarini {
		max-width: 200px;
	}
	#infoUser .boxIU {
		margin: 0 0 15px;
	}
}

@media screen and (max-height: 600px) {
	#logo-cral-menarini {
		max-width: 150px;
	}
	#infoUser {
		font-size: 12px;
	}
}

/***** EOF CARD *****/

/***** BOF CHECKWINDOW *****/

#checkWindowMobile {
	display: none;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.9);
	position: fixed;
	top: 0;
	left: 0;
	justify-content: center;
	align-items: center;
	z-index: 9999999999;
}

.containerCW {
	color: #FFF;
	text-align: center;
}

.containerCW .mdi {
	font-size: 50px;
}

.containerCW span {
	font-size: 17px;
}

@media screen and (orientation:landscape) {
	#checkWindowMobile {
		display: flex;
	}
}

/***** EOF CHECKWINDOW *****/

/***** BOF POPUPINSTALL *****/

#openPopupInfoInstalleriOS {
	margin-top: 30px;
	text-decoration: underline;
}

#boxPopupInfoInstall {
	position: absolute;
	top: 0;
	width: 100%;
	display: none;
	justify-content: center;
}

#boxPopupInfoInstall.show {
	animation-name: show;
	animation-duration: 2s;
	animation-fill-mode: forwards;
}

@keyframes show {
	from {
		top: 0;
	}
	to {
		top: 85vh;
	}
}

#popupInfoInstalleriOS {
	width: 300px;
	height: 175px;
	background: #dddddd;
	border-radius: 25px;
	position: absolute;
	bottom: 20px;
	box-shadow: 0 0 16px #00000088;
	padding: 10px;
	text-align: center;
}

#linguetta {
	position: absolute;
	width: 0;
	height: 0;
	border-left: 20px solid transparent;
	border-right: 20px solid transparent;
	border-top: 20px solid #ddd;
	bottom: 0;
	z-index: 9;
}

#popupInfoInstalleriOS img {
	width: 70px;
	padding-bottom: 5px;
}

#popupInfoInstalleriOS p {
	font-size: 15px;
	margin: 0;
	font-family: sans-serif;
	padding-top: 5px;
}

#closePopupInfoInstalleriOS {
	position: absolute;
	top: -20px;
	right: -10px;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border: 1px solid #666;
	background-color: #ddd;
	color: #666;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 25px;
}

/***** EOF POPUPINSTALL *****/

#boxLoader {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
}

.infoLoader {
	display: none;
	position: absolute;
	bottom: calc(50vh - 200px);
}

.infoLoader[data-loader="update"]{
	display: block;
}

#loader {
	color: #333;
	font-size: 60px;
	text-indent: -9999em;
	overflow: hidden;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	margin: 72px auto;
	position: relative;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
	animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}

@-webkit-keyframes load6 {
	0% {
		box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	}
	5%, 95% {
		box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	}
	10%, 59% {
		box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
	}
	20% {
		box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
	}
	38% {
		box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
	}
	100% {
		box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	}
}

@keyframes load6 {
	0% {
		box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	}
	5%, 95% {
		box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	}
	10%, 59% {
		box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
	}
	20% {
		box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
	}
	38% {
		box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
	}
	100% {
		box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	}
}

@-webkit-keyframes round {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes round {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}