.dialog-background {
position: fixed;
background: #00000033;
z-index: 999999998;
width: 100vw;
height: 100vh;
top: 0;
left: 0;
}

.dialog {
position: fixed;
background: #ffffff;
box-shadow: 0 0 16px #00000088;
width: 240px;
top: 50vh;
left: calc(50vw - 120px);
transform: translateY(-50%);
text-align: center;
border-radius: 15px;
animation: dialogbubble .25s 1;
z-index: 999999999;
}

@keyframes dialogbubble {
0% {
	transform: translateY(-50%) scale(1);
}
50% {
	transform: translateY(-50%) scale(1.05);
}
100% {
	transform: translateY(-50%) scale(1);
}
}

.dialog-title {
padding: 15px 15px 0 15px;
font-size: 17px;
font-weight: bold;
}

.dialog-message {
padding: 15px;
font-size: 15px;
}

.dialog-buttons {
display: flex;
border-top: 1px solid #eeeeee;
}

.dialog-button {
flex: 1 1 50%;
padding: 10px;
border-right: 1px solid #eeeeee;
font-size: 15px;
font-weight: bold;
cursor: pointer;
}

.dialog-button:first-child {
border-bottom-left-radius: 15px;
}

.dialog-button:last-child {
border-bottom-right-radius: 15px;
border-right: 0;
}

.d-none {
display: none!important;
}

.tile {
background: #000000;
position: fixed;
}
