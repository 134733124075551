/***** BOF ASSETS *****/

#downloadUI
{
position:absolute;
width:100vw;
top:calc(50vh + 150px);
}

.downloadProgress
{
display:none;
/*width:100vw;*/
margin:0 auto;
height:20px;
line-height:20px;
font-size:15px;
overflow:hidden;
text-align:center;
}


.downloadIndicator 
{
display:none;
width:320px;
margin:15px auto;
height:5px;
overflow:hidden;
background:#eeeeee;
}

.downloadCompleted
{
width:0%;
margin:0;
height:5px;
float:left;
background:#888888;
}

/***** EOF ASSETS *****/
